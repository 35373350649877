import * as React from 'react';
import Grid from '@mui/material/Grid';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { initialize_title, modify_title } from '../../main/App';
import { AbstractPage } from '../../main/AbstractPage';
import { DateInputExt, AbstractForm } from '../../main/AbstractForm';

dayjs.extend(utc);
dayjs.extend(timezone);



class Schedule extends AbstractPage {
	constructor() {
		super('/feature/schedule.basic');
		this.state = { info: null };
		const currTime = Math.floor(Date.now() / 1000);
		this.form = new AbstractForm('', '', { time: currTime });
	}

	result(result, info) {
		this.setState({ info: info });
	}

	dayObj(timestamp = null) {
		const obj = timestamp ? dayjs.unix(timestamp) : dayjs();
		if (!this.state.info || this.state.info.timezone === '')
			return obj.tz(dayjs.tz.guess());
		else
			return obj.tz(this.state.info.timezone);
	}

	getTimeStr(time, str) {
		console.log(time.hour());
		return str.replace('%A%', time.year()).replace('%B%', ('0' + (time.month() + 1)).slice(-2)).replace('%C%', ('0' + time.date()).slice(-2))
			.replace('%D%', ('0' + time.hour()).slice(-2)).replace('%E%', ('0' + time.minute()).slice(-2)).replace('%F%', ('0' + time.second()).slice(-2));
	}

	tdraw() {
		const list = [];
		const d = this.dayObj().hour(0);
		for (let i = 0; i < 1440; i += this.state.info.slotLength)
		{
			list.push(<Grid item xs={12}>
				{this.getTimeStr(d.minute(i), window.lan.schedule.time_str)}
			</Grid>);
		}

		this.form.value._time = this.dayObj(this.form.value.time);
		return (<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
			<Grid item xs={12}>
				<ArrowBackIosNewIcon fontSize="large" style={{ cursor: 'pointer' }} onClick={() => { this.form.value.time -= 86400; this.forceUpdate(); }} /><DateInputExt form={this.form} id="_time" label={window.lan.schedule.date} onChange={() => { this.form.value.time = this.form.value._time.unix(); this.forceUpdate(); }} fullwidth /><ArrowForwardIosIcon fontSize="large" style={{ cursor: 'pointer' }} onClick={() => { this.form.value.time += 86400; this.forceUpdate(); }} />
			</Grid>
			{list}
		</Grid>);
	}

	draw() {
		initialize_title();
		modify_title(2, window.lan.schedule.title);
		return this.pdraw([{ info: window.lan.schedule.title }], null, null, this.tdraw());
	}
}



export default Schedule;